import React from "react";
import styled from "styled-components";
import Post from "../post";

const PostListComponent: React.FC<{ posts: Post[] }> = ({ posts }) => {
  return (
    <PostList>
      {posts.map((post, index) => (
        <Post post={post} key={index} />
      ))}
    </PostList>
  );
};

export default PostListComponent;

const PostList = styled.div`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
`;
