import React from "react";
import styled from "styled-components";

const PostComponent: React.FC<{ post: Post }> = ({ post }) => {
  return (
    <Post>
      <PostTitle>{post.title}</PostTitle>
    </Post>
  );
};

export default PostComponent;

const Post = styled.div`
  width: 100%;
  padding: 20px 0px;
`;
const PostTitle = styled.a`
  font-family: ${(props) => props.theme.primaryFontFamily};
  font-weight: bold;
  font-size: 1.4rem;
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  transition: 0.2s all ease-in-out;

  &:hover {
    color: ${(props) => props.theme.secondaryColor};
    transition: 0.2s all ease-in-out;
  }
`;
