import { useQuery, QueryStatus } from "react-query";

function usePosts(): [posts: Array<Post> | undefined, status: QueryStatus] {
  const { data: posts, status } = useQuery<Array<Post>>("posts", () =>
    fetch("https://jsonplaceholder.typicode.com/posts?_start=0&_limit=5").then(
      (res) => res.json()
    )
  );

  return [posts, status];
}

export default usePosts;
