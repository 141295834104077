import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import HomePage from "./pages/Home.page";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import theme from "./config/theme";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <GlobalStyle />
          <BrowserRouter>
            <Routes>
              <Route index element={<HomePage />} />
            </Routes>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Avenir;
    src: url("./assets/fonts/AvenirNextLTPro-Bold.otf") format('truetype');
    font-weight: bold;
    font-style: bold;
  }

  @font-face {
    font-family: Avenir;
    src: url("./assets/fonts/AvenirNextLTPro-Heavy.otf") format('truetype');
    font-weight: 900;
    font-style: bold;
  }
  body {
    margin:0;
    background-color: #2c3e50;
  }
`;

export default App;

// font-family: 'Koulen', cursive;
// font-family: 'Roboto', sans-serif;
