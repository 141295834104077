import React from "react";
import styled from "styled-components";
import PostList from "../components/post-list";
import usePosts from "../hooks/usePosts.hook";
import me from "../assets/images/me.jpeg";

const HomePage: React.FC = () => {
  const [posts, status] = usePosts();

  return (
    <HomeContainer>
      <PresentationContainer>
        <PresentationTextContainer>
          <PresentationTitle>Hello, World!</PresentationTitle>
          <PresentationText>
            I'm Igor Felipe, a brazilian{" "}
            <strong>senior front-end developer</strong> working on{" "}
            <strong>americanas sa</strong>. I like to play games and write{" "}
            <strong>tech articles</strong>. To see what I've already done and
            know more about me and my <strong>work</strong>, click here to check
            my <strong>resume</strong>.
          </PresentationText>
        </PresentationTextContainer>
        <PresentationPhoto src={me} />
      </PresentationContainer>
      <LastPostsTitle>Last Posts</LastPostsTitle>
      {status === "success" && <PostList posts={posts as Post[]} />}
    </HomeContainer>
  );
};

export default HomePage;

const HomeContainer = styled.main`
  width: 100%;
  max-width: 800px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  margin: 40px auto;
  box-sizing: border-box;
`;

const PresentationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const PresentationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const PresentationTitle = styled.span`
  font-family: "Avenir", cursive;
  font-weight: 900;
  font-size: 2.5rem;
  color: ${(props) => props.theme.secondaryColor};
`;

const PresentationText = styled.span`
  font-family: ${(props) => props.theme.secondaryFontFamily};
  color: ${(props) => props.theme.primaryColor};
  line-height 2.0;
  font-size: 1.1rem;

  & > strong {
    font-family: ${(props) => props.theme.primaryFontFamily};
    font-weight: bold;
    color: ${(props) => props.theme.secondaryColor};
  }
`;

const PresentationPhoto = styled.img`
  width: 300px;
  border-radius: 50%;
  border: 5px solid ${(props) => props.theme.secondaryColor};
`;

const LastPostsTitle = styled.span`
  font-family: ${(props) => props.theme.primaryFontFamily};
  font-weight: 900;
  font-size: 1.8rem;
  color: ${(props) => props.theme.secondaryColor};
  margin-top: 40px;
`;
